




import Vue from 'vue'
import { CHART_COLORS } from '../../constants'
import { cloneDeep as _cloneDeep } from 'lodash'
import { ExchangeWalletPortfolioRes } from '@/services/exchange/models'
import Filters from '@/filters'

export default Vue.extend({
  props: {
    dataExchanges: {
      type: Array as () => ExchangeWalletPortfolioRes[],
      required: true
    }
  },

  data(): {
    pieChartOptions: any
    key: boolean
  } {
    return {
      pieChartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          with: '150px',
          height: '150px',
          spacing: [0, 0, 0, 0],
          backgroundColor: '#1f2324'
        },
        title: {
          text: ''
        },
        tooltip: {
          useHTML: true,
          pointFormat:
            '<div><p>{series.name}: {point.percentage:.1f}%</p><p>Amount: {point.amount}</p><p>€: {point.balanceEur}</p><p>$: {point.balanceUsd}</p></div>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: _cloneDeep(CHART_COLORS),
            dataLabels: {
              enabled: false
            }
          }
        },
        series: [
          {
            name: 'Percentage',
            data: []
          }
        ],
        credits: {
          enabled: false
        }
      },
      key: false
    }
  },

  methods: {
    calculateChart() {
      const exchangeBalance = _cloneDeep(this.dataExchanges)

      const usd = exchangeBalance[0]
      const eur = exchangeBalance[1]

      const seriesData = []

      for (const key in usd) {
        if (key !== 'metadata') {
          seriesData.push({
            name: usd[key].ccy,
            y: usd[key].share ? usd[key].share : 0,
            amount: usd[key].balance_ccy ? Filters.toFloatNumberWithCommas(usd[key].balance_ccy) : 0,
            balanceUsd: usd[key].balance_usd ? Filters.toFloatNumberWithCommas(usd[key].balance_usd) : 0,
            balanceEur:
              // eslint-disable-next-line no-prototype-builtins
              eur.hasOwnProperty(key) && eur[key].balance_eur
                ? Filters.toFloatNumberWithCommas(eur[key].balance_eur)
                : 0
          })
        }
      }

      this.pieChartOptions.series[0].data = seriesData
      this.key = !this.key
    }
  },

  watch: {
    dataExchanges: {
      handler() {
        this.calculateChart()
      },
      deep: true
    }
  }
})
